import { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { InputGroup } from 'react-bootstrap';
import { toLocalDate, toServerDate } from '../../utils/Helper';

const MpDatePicker = (props) => {
  const {
    startDate,
    endDate,
    isInvalid,
    disabled,
    onApply,
    className = '',
    controlId,
    placeholder,
    inputClass,
    minDate,
    maxDate,
    errorMessage,
    inputProps,
    ...restProps
  } = props;

  const { initialSettings } = restProps;
  const format = initialSettings.locale?.format || 'DD-MMM-YYYY';

  let displayValue = startDate
    ? `${toLocalDate(startDate, format)} to ${toLocalDate(endDate, format)}`
    : '';
  displayValue = initialSettings.singleDatePicker ? toLocalDate(startDate, format) : displayValue;

  function onDateSelect($event, picker) {
    if (disabled) {
      return;
    }
    const { startDate, endDate } = { ...picker };
    const date = {
      startDate: toServerDate(startDate),
      endDate: toServerDate(endDate),
    };
    onApply(date, $event);
  }

  // 1. If date is not valid or empty so set new date (today's date)
  // 2. To highlight the date in calendar:
  // Note: startDate is a prop inside initialSettings
  moment(startDate)?._isValid
    ? (initialSettings.startDate = moment(startDate))
    : (initialSettings.startDate = new Date());

  return (
    <DateRangePicker autoApply popperPlacement="bottom" onApply={onDateSelect} {...restProps}>
      <InputGroup>
        <Form.Control
          isInvalid={isInvalid}
          placeholder={placeholder || 'Select Dates'}
          disabled={disabled}
          autoComplete="off"
          className={`datepicker-field form-control-one ${inputClass}`}
          onChange={() => {}}
          value={displayValue}
          {...inputProps}
        />
        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
      </InputGroup>
    </DateRangePicker>
  );
};

MpDatePicker.propTypes = {
  errorMessage: PropTypes.string,
  inputClass: PropTypes.string,
  isInvalid: PropTypes.bool,
  controlId: PropTypes.string,
};

export default MpDatePicker;
