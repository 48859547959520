import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';
import { buildUrl } from './Helper';
import { SERVICE } from '../constants/Constants';

const signedUrlMap = {
  RECYCLER: ApiEndpoints.DOCUMENT.RECYCLER_SIGNED_URL,
  TRANSPORTER: ApiEndpoints.DOCUMENT.RECYCLER_SIGNED_URL,
  PURCHASE_ORDER: ApiEndpoints.DOCUMENT.PURCHASE_ORDER_SIGNED_URL,
  SALE_ORDER: ApiEndpoints.DOCUMENT.PURCHASE_ORDER_SIGNED_URL,
  LISTING: ApiEndpoints.DOCUMENT.PURCHASE_ORDER_SIGNED_URL,
  MATERIAL_TYPE: ApiEndpoints.DOCUMENT.SIGNED_URL,
  ITEM: ApiEndpoints.DOCUMENT.SIGNED_URL,
  REQUISITION: ApiEndpoints.DOCUMENT.SIGNED_URL,
  CUSTOMER: ApiEndpoints.DOCUMENT.SIGNED_URL,
  CATEGORY: ApiEndpoints.DOCUMENT.SIGNED_URL,
};

const newSignedUrlMap = {
  CUSTOMER: ApiEndpoints.DOCUMENT.SIGNED_URL_STAKEHOLDER,
  BANK_DETAILS: ApiEndpoints.DOCUMENT.SIGNED_URL_STAKEHOLDER,
  RECYCLER: ApiEndpoints.DOCUMENT.SIGNED_URL_STAKEHOLDER,
};
/**
 * Api call to get Signed URL
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const getSignedURL = (fileDetails, successCallback, failureCallback) => {
  axios
    .post(
      fileDetails?.type === 'CANCELED_CHECK' ||
        fileDetails?.type === 'INCORPORATION_REGISTRATION_CERTIFICATE'
        ? newSignedUrlMap[fileDetails.entity]
        : signedUrlMap[fileDetails.entity] || ApiEndpoints.DOCUMENT.SIGNED_URL,
      fileDetails
    )
    .then(
      (response) => {
        if (response.status === 200) {
          successCallback(response);
        }
      },
      (error) => {
        failureCallback(error);
      }
    );
};

/**
 * Upload file to S3 bucket
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadFileToS3 = (
  { signedURL, file, fileType },
  signedURLResponse,
  successCallback,
  failureCallback,
  progressCallback,
  fileDetails
) => {
  const instance = axios.create({
    headers: { 'Content-Type': fileType },
    timeout: 60000,
    onUploadProgress: progressCallback,
  });
  delete instance.defaults.headers.common.Authorization;
  instance
    .put(signedURL, file)
    .then((response) => {
      if (response.status === 200) {
        if (
          fileDetails.entity !== 'RECYCLER' &&
          fileDetails.entity !== 'TRANSPORTER' &&
          fileDetails.type !== null &&
          fileDetails?.type !== 'JOURNAL_VOUCHER'
        ) {
          attachDocument(
            fileDetails,
            (attachResponse) => {
              successCallback(attachResponse);
            },
            failureCallback
          );
        } else {
          successCallback(signedURLResponse);
        }
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};

export const uploadFileToS3Recycler = (
  { signedURL, file, fileType },
  signedURLResponse,
  successCallback,
  failureCallback,
  progressCallback,
  fileDetails
) => {
  const instance = axios.create({
    headers: { 'Content-Type': fileType },
    timeout: 60000,
    onUploadProgress: progressCallback,
  });

  delete instance.defaults.headers.common.Authorization;
  instance
    .put(signedURL, file)
    .then((response) => {
      if (response.status === 200) {
        attachDocument(
          fileDetails,
          (attachResponse) => {
            successCallback(attachResponse);
          },
          failureCallback
        );
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};
/**
 *
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadDocument = (
  fileDetails,
  file,
  signedDoc,
  successCallback,
  failureCallback,
  progressCallback,
  isRecyclerValidationDocs
) => {
  getSignedURL(
    fileDetails,
    (signedURLResponse) => {
      signedDoc(signedURLResponse.data);
      let uploadUrl;

      if (
        fileDetails.entity === 'RECYCLER' ||
        fileDetails.entity === 'TRANSPORTER' ||
        (fileDetails.entity === 'BANK_DETAILS' && fileDetails.type === 'CANCELED_CHECK') ||
        fileDetails.type === 'INCORPORATION_REGISTRATION_CERTIFICATE'
      ) {
        uploadUrl = signedURLResponse.data.signedUrl;
      } else {
        uploadUrl = signedURLResponse.data.uploadUrl;
      }
      const s3Details = {
        signedURL: uploadUrl,
        file,
        fileType: file.type,
      };
      isRecyclerValidationDocs
        ? uploadFileToS3Recycler(
            s3Details,
            signedURLResponse,
            successCallback,
            failureCallback,
            progressCallback,
            { ...signedURLResponse.data, documentStatus: fileDetails.documentStatus }
          )
        : uploadFileToS3(
            s3Details,
            signedURLResponse,
            successCallback,
            failureCallback,
            progressCallback,
            { ...signedURLResponse.data, documentStatus: fileDetails.documentStatus }
          );
    },
    failureCallback
  );
};

/**
 * @deprecated - use useDocument hook
 * Upload file to S3 bucket
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const attachDocument = (file, successCallback, failureCallback) => {
  axios({
    url:
      file?.entity === 'RECYCLER' ||
      file?.entity === 'TRANSPORTER' ||
      (file?.entity === 'BANK_DETAILS' && file?.type === 'CANCELED_CHECK') ||
      file?.type === 'INCORPORATION_REGISTRATION_CERTIFICATE'
        ? ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT_STAKEHOLDER
        : ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT,
    method: 'post',
    data: file,
  })
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      failureCallback(error);
    });
};

/**
 * @deprecated
 * Upload file to S3 bucket
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const recyclerDocumentAttach = (file, successCallback, failureCallback) => {
  axios({
    url: ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT_STAKEHOLDER,
    method: 'post',
    data: file,
  })
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};
/**
 * @deprecated
 * @param file
 * @param successCallback
 * @param failureCallback
 */
export const purchaseOrderDocumentAttach = (file, successCallback, failureCallback) => {
  axios({
    url: ApiEndpoints.DOCUMENT.ATTACH.PURCHASE_ORDER_ATTACH,
    method: 'post',
    data: file,
  })
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};

/**
 * @deprecated - use deleteDocument from hook useDocuments
 * Api call to allocate quantity to a work order
 * @param deliveryID
 * @param successCallback
 * @param failureCallback
 */
export const deleteDocument = async (id, type) => {
  const url = buildUrl(
    type === SERVICE.STAKEHOLDER
      ? ApiEndpoints.DOCUMENT.DELETE_DOC_STAKEHOLDER + id
      : ApiEndpoints.DOCUMENT.DELETE_DOC,
    { id }
  );
  return type === SERVICE.STAKEHOLDER
    ? axios({ url, method: 'put' })
    : axios({ url, method: 'delete' });
};

/**
 * @deprecated
 * @param id
 * @param successCallback
 * @param failureCallback
 */
export const deletePODocument = (id, successCallback, failureCallback) => {
  axios({
    url: buildUrl(ApiEndpoints.DOCUMENT.DELETE.PURCHASE_ORDER_DELETE, { id }),
    method: 'delete',
  }).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};
