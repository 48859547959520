import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { useRegion } from '../../../hooks';

const CityDropdown = ({
  stateId,
  onChange,
  isAllCitiesRequired = false,
  isDisabled = false,
  value,
  isMulti,
  valueKey,
  isOptionDisabled,
  ...props
}) => {
  const { citiesFlat } = useRegion();

  let sortedCities = _.sortBy(citiesFlat, 'name');
  // sortedCities = sortedCities?.filter((city) => city?.duplicateCity !== null);
  // sortedCities = _.uniqBy(sortedCities, 'name');

  if (isAllCitiesRequired) {
    const arr2 = [{ id: 'all', type: 'CITY', name: 'All cities' }];
    sortedCities = arr2.concat(sortedCities);
  }

  const filterCities = stateId
    ? sortedCities?.filter((city) => city?.parent?.id === stateId)
    : sortedCities;

  return (
    <DropdownSelect
      options={filterCities}
      valueKey={valueKey}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isOptionDisabled={isOptionDisabled}
      {...props}
    />
  );
};

CityDropdown.propTypes = {
  stateId: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default CityDropdown;
