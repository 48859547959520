const Colors = {
  warning_100: '#FFFAEB',
  success_50: '#ECFDF3',
  bg_grey: '#F9F9F9',
  border_color: '#000000',
  base_white: '#FFFFFF',
  text_grey: '#475467',
  text_gray: '#1F2937',
  text_silver: '#98A2B3',
};

export default { Colors };
