import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { isUndefined } from 'lodash';
import DropdownSelect from '../../shared/components/DropdownSelect';
import { CASH_CHALLAN_DURATION, RECALCULATION } from '../../shared/constants/Constants';
import { CASH_CHALLAN_PAID } from '../../shared/utils/StringConstants';

const VerticalDetail = ({ materialTypes, venderTypesData, conditionValue }) => {
  const { errors, values, setFieldValue } = useFormikContext();

  const extractedMaterialType = materialTypes?.map((item) => {
    return { id: item.id, value: item.name };
  });

  const conditionValues = conditionValue?.map((item, index) => {
    return { id: index + 1, value: item };
  });

  const handleChangeValue = (name, selectedOption) => {
    setFieldValue(name, selectedOption?.value || '');
  };

  useEffect(() => {
    if (values?.condition && !values?.condition.some((cond) => cond?.value === CASH_CHALLAN_PAID)) {
      setFieldValue('cashChallanDuration', 0);
    }
  }, [values?.condition]);
  return (
    <>
      <hr />
      <h5 className="mb-3 mt-3 p-2">Vertical, Vendor and Conditions Detail</h5>
      <hr />
      <Form>
        <Form.Row>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Vertical Type</Form.Label>
            <DropdownSelect
              name="verticalType"
              value={values?.materialType}
              onChange={(e) => handleChangeValue('materialType', e)}
              style={{ borderRadius: '20px' }}
              options={extractedMaterialType}
              getOptionLabel={(e) => e.value}
              getOptionValue={(e) => e.value}
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.materialType}</span>
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Vendor Type</Form.Label>
            <DropdownSelect
              name="vendorType"
              value={values?.aggregatorType}
              onChange={(e) => handleChangeValue('aggregatorType', e)}
              options={venderTypesData}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.value}
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.aggregatorType}</span>
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Select Conditions</Form.Label>
            <DropdownSelect
              name="condition"
              value={values.condition}
              onChange={(e) => setFieldValue('condition', e)}
              options={conditionValues}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.value}
              isMulti
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.condition}</span>
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Recalculation</Form.Label>
            <DropdownSelect
              name="recalculation"
              value={values?.intervalType}
              onChange={(e) => handleChangeValue('intervalType', e)}
              options={RECALCULATION}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.value}
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.intervalType}</span>
            </Form.Control.Feedback>
          </Form.Group>
          {!isUndefined(values?.condition) &&
            values?.condition.some((cond) => cond.value === CASH_CHALLAN_PAID) && (
              <Form.Group as={Col} md="4" className="mb-3">
                <Form.Label>Cash Challan Duration</Form.Label>
                <DropdownSelect
                  name="cashChallanDuration"
                  value={values?.cashChallanDuration}
                  onChange={(e) => setFieldValue('cashChallanDuration', e?.value || 0)}
                  options={CASH_CHALLAN_DURATION}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                  valueKey="value"
                />
                <Form.Control.Feedback type="isInvalid">
                  <span style={{ color: '#ff7272' }}>{errors?.cashChallanDuration}</span>
                </Form.Control.Feedback>
              </Form.Group>
            )}
        </Form.Row>
      </Form>
    </>
  );
};

export default VerticalDetail;
