import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'formik';
import { flatMap, uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PageContainer, FormField, Loader } from '../../shared/components';
import MaterialTypeDropdown from '../../shared/components/Dropdowns/MaterialTypeDropdown';
import { useCatalog } from '../../hooks';
import './FinanceWorkarea.scss';
import StatusCard from '../../shared/components/Cards/StatusCard';
import SummaryCard from '../../shared/components/Cards/SummaryCard';
import { useFinanceDashboard } from '../../hooks/hooks';

const FinanceWorkarea = ({ props }) => {
  const [selectedType, setSelectedType] = useState(0);
  const [typeOptions, setTypeOptions] = useState([]);
  const { items } = useCatalog();
  const history = useHistory();

  const [{ loading, error, value: financeData }, { doGetFinanceDashboard }] = useFinanceDashboard();

  useEffect(() => {
    doGetFinanceDashboard();
  }, [doGetFinanceDashboard]);

  useEffect(() => {
    let options = flatMap(items).map((item) => item?.categoryDTO?.materialType);
    options = uniqBy(options, (e) => e?.id);
    setTypeOptions([{ name: 'All', id: 0 }, ...options]);
  }, []);

  const typeHandler = (e) => {
    if (e !== null) {
      setSelectedType(e?.id);
    } else {
      setSelectedType('');
    }
  };

  useEffect(() => {
    if (typeOptions?.length > 0) {
      setSelectedType(typeOptions[0]);
    }
  }, [typeOptions]);

  const tranformedPendingAggregatorPayments = financeData?.pending_aggregator_payments && {
    pending_initiation: [
      financeData?.pending_aggregator_payments?.pending_initiation_count || '0',
      financeData?.pending_aggregator_payments?.pending_initiation_amount || '0',
    ],
    pending_processing: [
      financeData?.pending_aggregator_payments?.pending_processing_count || '0',
      financeData?.pending_aggregator_payments?.pending_processing_amount || '0',
    ],
    pending_approval: [
      financeData?.pending_aggregator_payments?.pending_approval_count || '0',
      financeData?.pending_aggregator_payments?.pending_approval_amount || '0',
    ],
  };

  const transformedReceivablesData = financeData?.receivables && {
    outstanding_amount: [
      financeData?.receivables?.outstandingCount || '0',
      financeData?.receivables?.outstandingAmount || '0',
    ],
    total_overdue: [
      financeData?.receivables?.overDueCount || '0',
      financeData?.receivables?.outstandingAmount - financeData?.receivables?.current || '0',
    ],
    unapplied_credits: [
      financeData?.receivables?.unappliedCount || '0',
      financeData?.receivables?.unusedAmount || '0',
    ],
    received_today: [
      financeData?.receivables?.receivedCount || '0',
      financeData?.receivables?.todayReceived || '0',
    ],
  };

  const transformedPendingVendorOnboarding = financeData?.pending_vendor_onboarding && {
    agreement: financeData?.pending_vendor_onboarding?.agreement || '0',
    VKYC: financeData?.pending_vendor_onboarding?.vkyc || '0',
    OSV: financeData?.pending_vendor_onboarding?.osv || '0',
    document: financeData?.pending_vendor_onboarding?.document || '0',
  };

  const transformedPendingTransactionVer = financeData?.pending_transaction_verification && {
    invoice_and_bills: financeData?.pending_transaction_verification?.invoice_and_Bills || '0',
    buyer_notes: financeData?.pending_transaction_verification?.buyer_notes || '0',
    seller_notes: financeData?.pending_transaction_verification?.seller_notes || '0',
  };

  return (
    <PageContainer className="scrollable-container">
      <Row className="align-items-center">
        <Col>
          <h3 style={{ fontSize: '24px' }}>Finance Workarea</h3>
        </Col>
        {/* <Col className="d-flex justify-content-end">
          <p className="fw-medium fs-6 mb-3 m-2" style={{ color: '#98A2B3' }}>
            Material / Vertical
          </p>
          <MaterialTypeDropdown
            isClearable
            isSearchable
            typeOptions={typeOptions}
            onChange={(e) => {
              typeHandler(e);
            }}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={selectedType}
            style={{ zIndex: 999 }}
          />
        </Col> */}
      </Row>
      <hr />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h5 className="sub-heading-style">Pending Vendor Onboarding</h5>
          <Row>
            {transformedPendingVendorOnboarding &&
              Object.entries(transformedPendingVendorOnboarding).map(([key, value]) => {
                return (
                  <Col>
                    <StatusCard
                      title={key}
                      count={value}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: '/customers/aggregators',
                      //     state: { customersAggregatorTab: 'APP-LEADS' },
                      //   });
                      // }}
                    />
                  </Col>
                );
              })}
          </Row>
          <hr />
          <h5 className="sub-heading-style">Pending Transaction Verification</h5>
          <Row>
            {transformedPendingTransactionVer &&
              Object.entries(transformedPendingTransactionVer).map(([key, value]) => {
                return (
                  <Col>
                    <StatusCard
                      title={key}
                      count={value}
                      // footerMsg="Average processing time: 1 day"
                      // onClick={() => {
                      //   history.push({
                      //     pathname: '/orders/saleorder',
                      //   });
                      // }}
                    />
                  </Col>
                );
              })}
          </Row>
          <hr />
          <h5 className="sub-heading-style">Pending Aggregator Payments</h5>
          <Row>
            {tranformedPendingAggregatorPayments &&
              Object.entries(tranformedPendingAggregatorPayments).map(([key, value]) => {
                return (
                  <Col>
                    <SummaryCard
                      title={key}
                      count={value[0]}
                      amount={value[1]}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: '/payments/aggregatorpayments',
                      //   });
                      // }}
                    />
                  </Col>
                );
              })}
          </Row>
          <hr />
          <h5 className="sub-heading-style">Receivables</h5>
          <Row>
            {transformedReceivablesData &&
              Object.entries(transformedReceivablesData).map(([key, value]) => {
                return (
                  <Col>
                    <SummaryCard
                      title={key}
                      count={value[0]}
                      amount={value[1]}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: '/receivables',
                      //   });
                      // }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      )}
    </PageContainer>
  );
};

export default FinanceWorkarea;
