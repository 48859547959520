import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { KAM_LISTS_STATUS } from '../../constants/Constants';

const KamListingsStatusDropDown = ({ onChange, value, ...props }) => {
  const options = [
    KAM_LISTS_STATUS.DRAFT,
    KAM_LISTS_STATUS.IN_REVIEW,
    KAM_LISTS_STATUS.APPROVED,
    KAM_LISTS_STATUS.ON_HOLD,
    KAM_LISTS_STATUS.MATCH_FOUND,
    KAM_LISTS_STATUS.SOLD,
    KAM_LISTS_STATUS.EXPIRED,
    KAM_LISTS_STATUS.REJECTED,
    KAM_LISTS_STATUS.IN_PROGRESS,
    KAM_LISTS_STATUS.RETURN,
    KAM_LISTS_STATUS.RESELL,
    KAM_LISTS_STATUS.RESOLD,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      {...props}
    />
  );
};

KamListingsStatusDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default KamListingsStatusDropDown;
