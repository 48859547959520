import React from 'react';
import { FaRupeeSign } from '@react-icons/all-files/fa/FaRupeeSign';
import { formatNumberToIndianSystem, toCapitalize } from '../../utils/Helper';
import Theme from '../../../theme/Theme';
import './CardStyles.scss';

const SummaryCard = ({ title, count, amount, onClick }) => {
  return (
    // eslint-disable-next-line
    <div className="card card-style" style={{ cursor: onClick && 'pointer' }} onClick={onClick}>
      <div className="card-body">
        <h6 style={{ color: Theme.Colors.text_grey }} className="mb-2">
          {toCapitalize(title)}
        </h6>
        <hr />
        <div className="d-flex align-items-center">
          <span
            className="card-count mr-2"
            style={{
              color: count === 0 ? Theme.Colors.text_silver : Theme.Colors.text_gray,
            }}>
            {count}
          </span>
          <div className="vertical-line" />
          <span className="card-amount" style={{ color: Theme.Colors.text_gray }}>
            ₹ {formatNumberToIndianSystem(amount)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
