import React from 'react';
import PropTypes from 'prop-types';
import { flatMap } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { SALE_ORDER_STATUS } from '../../constants/Constants';

const SaleOrderStatusDropdown = ({ onChange, value, ...props }) => {
  const options = [
    SALE_ORDER_STATUS.CANCELLED,
    {
      id: [SALE_ORDER_STATUS.PLACED, SALE_ORDER_STATUS.PENDING_AGGREGATOR_APPROVAL]
        .map((x) => x.id)
        .join(','),
      name: 'In Review',
      isVirtual: true,
    },
    SALE_ORDER_STATUS.PLACED,
    SALE_ORDER_STATUS.PENDING_AGGREGATOR_APPROVAL,
    {
      id: [
        SALE_ORDER_STATUS.APPROVED,
        SALE_ORDER_STATUS.TRUCK_ASSIGNED,
        SALE_ORDER_STATUS.MATERIAL_LOADED,
        // SALE_ORDER_STATUS.PAYMENT_DONE,
      ]
        .map((x) => x.id)
        .join(','),
      name: 'In Progress',
      isVirtual: true,
    },
    SALE_ORDER_STATUS.APPROVED,
    SALE_ORDER_STATUS.TRUCK_ASSIGNED,
    SALE_ORDER_STATUS.MATERIAL_LOADED,
    SALE_ORDER_STATUS.ORDER_VERIFIED,
    // SALE_ORDER_STATUS.PAYMENT_DONE,
    {
      id: [
        SALE_ORDER_STATUS.DISPATCHED,
        SALE_ORDER_STATUS.RECEIVED_BY_RECYCLER,
        SALE_ORDER_STATUS.RETURN,
        SALE_ORDER_STATUS.RESELL,
        SALE_ORDER_STATUS.QC_DONE,
        SALE_ORDER_STATUS.PARTIAL_PAYMENT_RECEIVED,
        SALE_ORDER_STATUS.ORDER_COMPLETED,
      ]
        .map((x) => x.id)
        .join(','),
      name: 'Post Dispatched',
      isVirtual: true,
    },
    SALE_ORDER_STATUS.DISPATCHED,
    SALE_ORDER_STATUS.RECEIVED_BY_RECYCLER,
    SALE_ORDER_STATUS.QC_DONE,
    SALE_ORDER_STATUS.RETURN,
    SALE_ORDER_STATUS.RESELL,
    SALE_ORDER_STATUS.PARTIAL_PAYMENT_RECEIVED,
    SALE_ORDER_STATUS.DOCUMENT_PENDING,
    SALE_ORDER_STATUS.ORDER_COMPLETED,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      {...props}
    />
  );
};

SaleOrderStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SaleOrderStatusDropdown;
