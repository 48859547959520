/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, Row, Tab, Tabs, ToggleButton } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../../shared/components';
import './OrderAllocation.scss';
// eslint-disable-next-line max-len
import OrderAllocationQueueList from '../../features/OrderAllocations/OrderAllocationQueueList';
// eslint-disable-next-line max-len
import OrderAllocationAssignedList from '../../features/OrderAllocations/OrderAllocationAssignedList';
// eslint-disable-next-line max-len
import OrderAllocationCompletedList from '../../features/OrderAllocations/OrderAllocationCompletedList';
import { DoShow, PrimaryButton } from '../../shared/components/GenericComponent';
// eslint-disable-next-line max-len
import OrderAllocationMembersDetailsModal from '../../features/OrderAllocations/OrderAllocationMembersDetailsModal';
import OrderManualAllocationModal from '../../features/OrderAllocations/OrderManualAllocationModal';
import { useAccount, useOrderAllocation } from '../../hooks/hooks';
import { useStakeholders } from '../../hooks';
import HasRolePermission, { useRoleAuthority } from '../../shared/HasRolePermission';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';
import { userActions } from '../../store/User/user.reducer';
import OrderAllocationHoldList from '../../features/OrderAllocations/OrderAllocationHoldList';
import OrderAllocationLogs from '../../features/OrderAllocations/OrderAllocationLogs';

const OrderAllocation = () => {
  const { accountDetails } = useAccount();
  const { hasRoleAuth } = useRoleAuthority();
  const { currentAccount } = useStakeholders();
  const [selectKey, setSelectKey] = useState(
    hasRoleAuth(ALL_MODULES?.ORDER_ALLOCATION, ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER)
      ? 'QUEUE'
      : 'ASSIGNED'
  );
  const [refresh, setRefresh] = useState(false);
  const [showMembersDetails, setShowMembersDetails] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);
  const [showManualAllocation, setShowManualAllocation] = useState(false);
  const [editItemObj, setEditItemObj] = useState({});
  const [selectOption, setSelectedOption] = useState('');
  const [orderLogs, setOrderLogs] = useState([]);

  const dispatch = useDispatch();
  const [
    {
      saveUserAvailabilityError,
      updateUserAvailabilityError,
      totalUsersError,
      totalUsersValue,
      saleOrderAllocationLoading,
      saleOrderAllocationValue,
    },
    { doSaveUserAvailability, doUpdateUserAvailability, doGetTotalUsers, doGetSaleOrderAllocation },
  ] = useOrderAllocation();

  useEffect(() => {
    doGetTotalUsers({ userId: currentAccount?.id });
  }, []);

  useEffect(() => {
    setShowAvailable(currentAccount?.isAvailable);
  }, [currentAccount]);
  const roleName = currentAccount?.authorities?.find(
    (item) => item === 'ROLE_MP_INVOICE_PROCESSOR'
  );
  const user = roleName ? currentAccount?.id || '' : '';

  const getSaleOrderAllocationList = async (params) => {
    await doGetSaleOrderAllocation({
      ...params,
      userId: params?.userId || user,
      allocationStatus: selectKey,
    });
  };

  const changeTab = (k) => {
    setSelectKey(k);
    setRefresh(true);
    setSelectedOption('');
  };

  const onToggleChange = (toggleVal) => {
    setShowAvailable(toggleVal?.target?.checked);

    if (showAvailable) {
      const params = {
        userId: currentAccount?.id,
        isAvailable: false,
      };
      doUpdateUserAvailability(params);
    } else {
      const params = {
        userId: currentAccount?.id,
        isAvailable: true,
      };
      doSaveUserAvailability(params);
    }
    dispatch(
      userActions.setUserDetails({
        ...accountDetails,
        isAvailable: toggleVal?.target?.checked,
      })
    );
  };

  const tabsData = [
    hasRoleAuth(ALL_MODULES?.ORDER_ALLOCATION, ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER) && {
      eventKey: 'QUEUE',
      title: 'Queue',
      component: (
        <OrderAllocationQueueList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
        />
      ),
    },
    {
      eventKey: 'ASSIGNED',
      title: 'Assigned',
      component: (
        <OrderAllocationAssignedList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
        />
      ),
    },
    {
      eventKey: 'HOLD',
      title: 'Hold',
      component: (
        <OrderAllocationHoldList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
        />
      ),
    },
    {
      eventKey: 'COMPLETED',
      title: 'Completed',
      component: (
        <OrderAllocationCompletedList
          loading={saleOrderAllocationLoading}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          setEditItemObj={setEditItemObj}
        />
      ),
    },
  ];
  return (
    <PageContainer>
      <div className="OrderAllocation">
        <Row className="align-items-center">
          <Col>
            <h3>Work flow Dashboard</h3>
            <p>Manage and assign tasks to your team members</p>
          </Col>
          <Col className="float-right">
            {/* <Row> */}
            <HasRolePermission
              moduleName={ALL_MODULES?.ORDER_ALLOCATION}
              permissionName={[ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER]}>
              <Col className="text-end me-custom mr-5">
                <div className="total-members float-right">
                  <span className="status-dot" />
                  <span>Total members - </span>
                  <strong>{totalUsersValue?.data?.length}</strong>
                  <PrimaryButton
                    label="View Details"
                    onClick={() => setShowMembersDetails(true)}
                    buttonStyle={{
                      color: '#256B67',
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      alignSelf: 'flex-end',
                    }}
                  />
                </div>
              </Col>
            </HasRolePermission>
            <HasRolePermission
              moduleName={ALL_MODULES?.ORDER_ALLOCATION}
              permissionName={[ALL_PERMISSIONS?.USER_AVAILABILITY]}>
              <Col xs="auto">
                <ButtonToolbar className="float-right">
                  <Button className="d-md-block d-xs-block btn-toggle" variant="clear">
                    <Toggle
                      className="float-left mr-2 p-0"
                      onChange={(toggleVal) => {
                        onToggleChange(toggleVal);
                      }}
                      checked={showAvailable}
                      icons={false}
                    />
                    Availability
                  </Button>
                </ButtonToolbar>
              </Col>
            </HasRolePermission>
            {/* </Row> */}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tabs
              className="mb-2 borderBottomWidth"
              id="controlled-tab-example"
              activeKey={selectKey}
              onSelect={(k) => {
                changeTab(k);
              }}>
              {tabsData?.map((item) => {
                return (
                  <Tab eventKey={item.eventKey} title={item.title}>
                    {item.component}
                  </Tab>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
      <OrderAllocationMembersDetailsModal
        show={showMembersDetails}
        setShow={setShowMembersDetails}
        userData={totalUsersValue?.data}
      />
      <OrderManualAllocationModal
        editItemObj={editItemObj}
        show={showManualAllocation}
        setShow={setShowManualAllocation}
        getSaleOrderAllocationList={getSaleOrderAllocationList}
        selectKey={selectKey}
        selectOption={selectOption}
        setSelectedOption={setSelectedOption}
      />
      <DoShow show={orderLogs?.length > 0}>
        <OrderAllocationLogs
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          editItemObj={editItemObj}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
        />
      </DoShow>
    </PageContainer>
  );
};

export default OrderAllocation;
