import { filter, find, groupBy, includes, isEmpty, map } from 'lodash';
import { useRole } from '../hooks';
import useRoleByPermission from '../hooks/useRoleByPermission';

const HasRolePermission = (props) => {
  const { hasRoleAuth } = useRoleAuthority();
  const { moduleName, permissionName, failureComponent, children } = props;

  // eslint-disable-next-line react/react-in-jsx-scope
  return <>{hasRoleAuth(moduleName, permissionName) ? children : failureComponent || null}</>;
};

export default HasRolePermission;

// hook useful to check the role permissions is present or not (i.e used for column hiding)

export const useRoleAuthority = () => {
  const { modulesData } = useRole();

  // creating map object for nav
  const { allPermissionsByRoleName } = useRoleByPermission();

  // filtering the only selected permissions
  const filteredPermissionByRoleName = filter(
    allPermissionsByRoleName,
    (option) => option?.hasAccess || (option?.hasAccess && option?.morePermission)
  );
  // linking permissions to modules
  const groupedModuleIds = groupBy(filteredPermissionByRoleName, 'moduleId');
  const mappedModulesByPermissions = map(groupedModuleIds, (moduleOption, key) => {
    const filteredModules = find(modulesData, (option) => {
      return option?.id === parseInt(key);
    });
    return {
      ...filteredModules,
      permissions: moduleOption,
    };
  });

  const hasRoleAuth = (moduleName, permissionName) => {
    let finalCheck = false;
    // return the mapped module
    const filteredModuleByGivenName = find(
      mappedModulesByPermissions,
      (option) => option?.name === moduleName
    );

    // return the mapped permission
    finalCheck = find(filteredModuleByGivenName?.permissions, (option) => {
      if (permissionName?.length > 1) {
        return includes(permissionName, option?.name);
      }
      return option?.name === permissionName?.[0];
    });

    return finalCheck !== undefined && true;
  };
  return { hasRoleAuth };
};

//   if (isEmpty(finalCheck)) {
//     return null;
//   }
//   return children;
