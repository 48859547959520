import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { PrimaryButton, SecondaryButton } from '../../../shared/components/GenericComponent';
import CustomStepper from '../../../shared/components/CustomerStepper/CustomStepper';
import { SEGMENT, TOAST_TYPES } from '../../../shared/constants/Constants';
import LeftArrow from '../../../assets/img/arrow_left.svg';
import SegmentationDetail from '../SegmentationDetail';
import VerticalDetail from '../VerticalDetail';
import { useCatalog } from '../../../hooks';
import { useSegmentation, useSegmentCondition } from '../../../hooks/hooks';
import {
  buildConditionsObject,
  editSegmentation,
  extendTypesValues,
  extractConditionDetails,
  segmentObject,
  transformedVendorSegmentValues,
} from '../SegmentUtils';
import { displayToastMessage, hasError } from '../../../shared/utils/Helper';
import { ErrorCode } from '../../../shared/constants';
import { CASH_CHALLAN_PAID } from '../../../shared/utils/StringConstants';

const SEGMENT_DETAILS = 'SegmentationDetails';
const VERTICAL_DETAILS = 'VerticalDetails';

const StyleModal = styled(Modal)`
  .modal-dialog {
    border-radius: 20px;
  }
`;

const BackButtonImageStyle = {
  marginLeft: '-5px',
  marginRight: '5px',
  width: '15px',
  height: '15px',
  justifyContent: 'center',
};

/**
 * Renders a modal for configuring segmentation.
 *
 * @param {Object} props - The properties for the SegmentationModel component.
 * @param {boolean} props.showState - A boolean indicating whether the state should be shown.
 * @param {boolean} props.show - A boolean indicating whether the modal should be shown.
 * @param {Object} [props.editData] - The data to edit, if any.
 * @param {Function} props.setUpdateApi - A function to set the update API.
 * @param {boolean} props.renderApi - A boolean indicating whether the API should be rendered.
 * @return {JSX.Element} The rendered SegmentationModel component.
 */
const SegmentationModel = ({ showState, show, editData, setUpdateApi, renderApi }) => {
  const vvcdFormik = useRef(null);
  const segmentationFormik = useRef(null);
  const { materialTypes = [], activeSellerTypes = [] } = useCatalog();
  const verticalConstantDetail = {
    materialType: '',
    aggregatorType: '',
    condition: [],
    intervalType: '',
    cashChallanDuration: 0,
  };
  const [activeStepper, setActiveStepper] = useState(0);
  const [activeSeller, setActiveSeller] = useState([]);
  const [updateEditedValue, setUpdateEditedValue] = useState({});
  const [screeHandler, setScreenHandler] = useState(VERTICAL_DETAILS);
  const editSegmentValues = transformedVendorSegmentValues(updateEditedValue);
  const [verticalDetails, setVerticalDetails] = useState(verticalConstantDetail);

  const [{ value: conditionValue }, { doSegmentCondition }] = useSegmentCondition();
  const [
    { error: vendorSegError, loading: vendorSegLoading, value: vendorSegValue },
    { doSaveVendorSegmentation },
  ] = useSegmentation();
  const [
    { error: updateSegError, loading: updateSegLoading, value: updateSegValue },
    { doUpdateVendorSegmentation },
  ] = useSegmentation();

  useEffect(() => {
    doSegmentCondition();
  }, []);

  useEffect(() => {
    setActiveSeller(extendTypesValues(activeSellerTypes));
  }, [activeSellerTypes]);

  useEffect(() => {
    setUpdateEditedValue(editData);
  }, [editData]);

  useEffect(() => {
    if (updateEditedValue) {
      setVerticalDetails({
        materialType: updateEditedValue?.materialType || '',
        aggregatorType: updateEditedValue?.aggregatorType || '',
        condition:
          updateEditedValue?.appliedConditionsOn &&
          extractConditionDetails(updateEditedValue?.appliedConditionsOn),
        intervalType: updateEditedValue?.intervalType || '',
        cashChallanDuration: updateEditedValue?.cashChallanDuration || 0,
      });
      nestedInitialValues();
    }
  }, [updateEditedValue]);

  /**
   * Sets the screen handler to SEGMENT_DETAILS and updates the active stepper to 1.
   *
   * @return {void}
   */
  const nextStepHandler = (newVerticalDetails) => {
    setVerticalDetails(newVerticalDetails);
    setScreenHandler(SEGMENT_DETAILS);
    setActiveStepper(1);
  };

  /**
   * Resets the screen handler to VERTICAL_DETAILS and sets the active stepper to 0.
   *
   * @return {void}
   */
  const backStepHandler = () => {
    setScreenHandler(VERTICAL_DETAILS);
    setActiveStepper(0);
  };

  /**
   * Renders a form for the vertical details.
   *
   * @return {JSX.Element} The rendered form.
   */
  const VerticalDetailForm = () => {
    return (
      <Formik
        initialValues={verticalDetails}
        enableReinitialize
        validationSchema={Yup.object({
          materialType: Yup.string().required('Required'),
          aggregatorType: Yup.string().required('Required'),
          condition: Yup.array().min(1, 'At least one condition is required'),
          intervalType: Yup.string().required('Required'),
          cashChallanDuration: Yup.number().when('condition', {
            is: (condition) => condition?.some((cond) => cond?.value === CASH_CHALLAN_PAID),
            then: Yup.number()
              .test('is-zero-required', 'Required', (value) => value !== 0)
              .required('Required'),
            otherwise: Yup.number().notRequired(),
          }),
        })}
        onSubmit={(values) => {
          nextStepHandler(values);
        }}
        innerRef={vvcdFormik}>
        {() => (
          <VerticalDetail
            materialTypes={materialTypes}
            venderTypesData={activeSeller}
            conditionValue={conditionValue}
          />
        )}
      </Formik>
    );
  };

  /**
   * Retrieves the vertical details from the verticalDetails object and maps them to a new array
   * with the following structure: { valueType: string, minValue: string, maxValue: string }.
   *
   * @return {Array<{valueType: string, minValue: string, maxValue: string}>}
   * The mapped array of vertical details.
   */
  const getVerticalDetails = useCallback(() => {
    return verticalDetails?.condition?.map((item) => ({
      valueType: item.value,
      minValue: '',
      maxValue: '',
    }));
  }, [verticalDetails]);

  /**
   * Returns the initial values for the SegmentationDetailForm.
   *
   * @return {Array<Object>}
   * An array of objects representing the initial values for the nested form.
   */
  const nestedInitialValues = () => {
    return updateEditedValue
      ? editSegmentation(
          verticalDetails?.condition,
          updateEditedValue?.appliedConditionsOn,
          editSegmentValues
        )
      : [{ segmentName: '', valueTypes: getVerticalDetails(), active: true }];
  };

  /**
   *
   * validationSchema for SegmentationDetailForm
   *
   * */
  const validationSchema = Yup.object({
    vendorSegmentValues: Yup.array().of(
      Yup.object({
        segmentName: Yup.string().required('Segment name is required'),
        valueTypes: Yup.array().of(
          Yup.object({
            valueType: Yup.string().required('Value type is required'),
            minValue: Yup.number().when('valueType', {
              is: 'Transactions',
              then: Yup.number()
                .required('Minimum value is required')
                .min(1, 'Minimum value must be 1 or more')
                .integer('Minimum value must be an integer')
                .typeError('Minimum value must be a `number` type'),
              otherwise: Yup.number().when('valueType', {
                is: 'Cash Challan Paid',
                then: Yup.number()
                  .required('Minimum value is required')
                  .min(1, 'Minimum value must be 1 or more')
                  .max(100, 'Maximum value must be 100 or less for Cash Challan Paid')
                  .typeError('Maximum value must be a `number` type'),
                otherwise: Yup.number().when('valueType', {
                  is: 'Seller Rating',
                  then: Yup.number()
                    .required('Minimum value is required')
                    .min(1, 'Minimum value must be 1 or more')
                    .max(10, 'Minimum value must be 10 or less for Seller Rating')
                    .integer('Minimum value must be an integer')
                    .typeError('Minimum value must be a `number` type'),
                  otherwise: Yup.number().when('valueType', {
                    is: 'Margin',
                    then: Yup.number()
                      .required('Minimum value is required')
                      .min(0, 'Minimum value must be 0 or more'),
                    otherwise: Yup.number()
                      .required('Minimum value is required')
                      .min(1, 'Minimum value must be 1 or more')
                      .typeError('Minimum value must be a `number` type'),
                  }),
                }),
              }),
            }),
            maxValue: Yup.mixed().when('valueType', {
              is: 'Cash Challan Paid',
              then: Yup.mixed()
                .required('Maximum value is required for Cash Challan Paid')
                .test(
                  'max-value',
                  'Maximum value must be 100 or less for Cash Challan Paid',
                  (value) => {
                    if (value === 999999) {
                      return true;
                    }
                    if (Number(value) > 100) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  'no-special-characters',
                  'Maximum value must not contain special characters',
                  (value) => {
                    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
                    if (typeof value === 'string' && specialCharRegex.test(value)) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  'max-value',
                  `Maximum value must be greater than or equal to the minimum value`,
                  (value, context) => {
                    const { minValue } = context.parent;
                    if (value === 999999) {
                      return true;
                    }
                    if (value === 'No Limit') {
                      return true;
                    }
                    return Number(value) >= minValue && Number(value) <= 100;
                  }
                )
                .typeError('Maximum value must be a number or "No Limit"'),
              otherwise: Yup.mixed().when('valueType', {
                is: 'Seller Rating',
                then: Yup.mixed()
                  .required('Maximum value is required for Seller Rating')
                  .test(
                    'max-value',
                    'Maximum value must be 10 or less for Seller Rating',
                    (value) => {
                      if (value === 999999) {
                        return true;
                      }
                      if (Number(value) > 10) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .test(
                    'no-special-characters',
                    'Maximum value must not contain special characters',
                    (value) => {
                      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
                      if (typeof value === 'string' && specialCharRegex.test(value)) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .test(
                    'max-value',
                    `Maximum value must be greater than or equal to the minimum value`,
                    (value, context) => {
                      const { minValue } = context.parent;
                      if (value === 999999) {
                        return true;
                      }
                      if (value === 'No Limit') {
                        return true;
                      }
                      return Number(value) >= minValue && Number(value) <= 10;
                    }
                  ),
                otherwise: Yup.mixed().when('valueType', {
                  is: 'Transactions',
                  then: Yup.mixed()
                    .required('Maximum value is required for Transactions')
                    .test(
                      'no-special-characters',
                      'Maximum value must not contain special characters',
                      (value) => {
                        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
                        if (typeof value === 'string' && specialCharRegex.test(value)) {
                          return false;
                        }
                        return true;
                      }
                    )
                    .test(
                      'max-value',
                      `Maximum value must be greater than or equal to the minimum value`,
                      (value, context) => {
                        const { minValue } = context.parent;
                        if (value === 999999) {
                          return true;
                        }
                        if (value === 'No Limit') {
                          return true;
                        }
                        return Number(value) >= minValue;
                      }
                    ),
                  otherwise: Yup.mixed()
                    .typeError('Maximum value must be a `number` type')
                    .test(
                      'max-value',
                      `Maximum value must be greater than 
                    or equal to the minimum value or be "No Limit"`,
                      (value, context) => {
                        const { minValue } = context.parent;
                        if (value === 999999) {
                          return true;
                        }
                        if (value === 'No Limit') {
                          return true;
                        }
                        return Number(value) >= minValue;
                      }
                    ),
                }),
              }),
            }),
          })
        ),
      })
    ),
  });

  /**
   * Cancels the save operation and resets the vertical details to their initial state.
   * Also calls the backStepHandler function and shows the state.
   *
   * @return {void} This function does not return anything.
   */
  const onCancelOnSave = () => {
    backStepHandler();
    setVerticalDetails(verticalConstantDetail);
    showState();
  };

  /**
   * Saves the vendor segmentation data to the server.
   *
   * @param {Object} payload - The payload containing the segmentation data.
   * @return {Promise<void>} -
   * A promise that resolves when the segmentation data is saved successfully.
   */
  const saveSegmentationApiCall = async (payload) => {
    if (updateEditedValue) {
      const segmentUpdateResponse = await doUpdateVendorSegmentation(payload);
      const error = hasError(segmentUpdateResponse);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
        return;
      }
      if (segmentUpdateResponse) {
        onCancelOnSave();
        setUpdateApi(!renderApi);
        displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.SEGMENT_DETAILS_UPDATE);
      }
    } else {
      const segmentSavedResponse = await doSaveVendorSegmentation(payload);
      const error = hasError(segmentSavedResponse);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
        return;
      }
      if (segmentSavedResponse) {
        onCancelOnSave();
        setUpdateApi(!renderApi);
        displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.SEGMENT_DETAILS);
      }
    }
  };

  /**
   * Renders a Formik form for the SegmentationDetail component.
   *
   * @return {JSX.Element} The rendered Formik form component.
   */
  const SegmentationDetailForm = () => {
    return (
      <Formik
        initialValues={{ vendorSegmentValues: nestedInitialValues() }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, actions) => {
          const segmentNames = values?.vendorSegmentValues.map((item) =>
            item?.segmentName?.toLowerCase()
          );
          if (segmentNames.length > Array.from(new Set(segmentNames)).length) {
            displayToastMessage(TOAST_TYPES?.ERROR, ErrorCode?.SEGMENT_NAME_DUPLICATE);
            return;
          }
          const payload = {
            ...verticalDetails,
            appliedConditionsOn: buildConditionsObject(verticalDetails?.condition),
            vendorSegmentValues: segmentObject(values?.vendorSegmentValues),
            id: updateEditedValue ? updateEditedValue?.id : null,
          };
          saveSegmentationApiCall(payload);
        }}
        innerRef={segmentationFormik}>
        {() => <SegmentationDetail verticalDetails={verticalDetails} />}
      </Formik>
    );
  };

  return (
    <>
      <StyleModal
        show={show}
        onHide={showState}
        enforceFocus={false}
        size="xl"
        animation
        onExit={() => {
          showState();
          onCancelOnSave();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Segmentation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomStepper steps={SEGMENT} activeStepper={activeStepper} />
          {screeHandler === VERTICAL_DETAILS && VerticalDetailForm()}
          {screeHandler === SEGMENT_DETAILS && SegmentationDetailForm()}
        </Modal.Body>
        <Modal.Footer className={activeStepper > 0 && 'd-flex justify-content-between'}>
          {activeStepper > 0 && (
            <SecondaryButton
              onClick={backStepHandler}
              label="Back"
              src={LeftArrow}
              imageStyle={BackButtonImageStyle}
            />
          )}
          <div className="d-flex">
            <SecondaryButton
              onClick={onCancelOnSave}
              label="cancel"
              buttonClassName="buttonStyle"
              buttonStyle={{ marginRight: '10px' }}
            />
            {SEGMENT.length - 1 === activeStepper ? (
              <PrimaryButton onClick={() => segmentationFormik.current.submitForm()} label="Save" />
            ) : (
              <PrimaryButton onClick={() => vvcdFormik.current.handleSubmit()} label="Next" />
            )}
          </div>
        </Modal.Footer>
      </StyleModal>
    </>
  );
};

export default SegmentationModel;
