import React, { useContext } from 'react';
import { Modal, Table } from 'react-bootstrap';

import { capitalize, find, flatMap, orderBy } from 'lodash';
import { Content } from '../../shared/components';
import './OrderAllocationMembersModal.scss';
import { useStakeholders } from '../../hooks';

function OrderAllocationMembersDetailsModal({ show, setShow, userData }) {
  function closeModal() {
    setShow(false);
  }

  const { kamUsers } = useStakeholders();

  return (
    <Modal
      animation
      onHide={closeModal}
      show={show}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
      contentClassName="my-modal"
      className="OrderAllocationMembersModal">
      <Modal.Header className="my-modal-header font-bold" closeButton>
        Total Members - {userData?.length}
      </Modal.Header>
      <Modal.Body>
        <Table className="table rcyl-table-panel mt-2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>User</th>
              <th>Availability</th>
            </tr>
          </thead>
          <tbody>
            {userData?.length > 0 ? (
              userData?.map((item, index) => {
                return (
                  <tr className="total-row">
                    <td>{`${index + 1}.`}</td>
                    <td>{item?.userName}</td>
                    <td>
                      <div
                        className="availabilityDiv"
                        style={{
                          borderColor: item?.isAvailable === 'true' ? '#ABEFC6' : '#FECDCA',
                          backgroundColor: item?.isAvailable === 'true' ? '#ECFDF3' : '#FEF3F2',
                        }}>
                        <div
                          className="dotView"
                          style={{
                            backgroundColor: item?.isAvailable === 'true' ? '#17B26A' : '#F04438',
                          }}
                        />
                        <span
                          style={{ color: item?.isAvailable === 'true' ? '#067647' : '#B42318' }}>
                          {item?.isAvailable === 'true' ? 'Available' : 'Unavailable'}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3">
                  <div className="no-data-message">
                    <span>No Data Found</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

export default OrderAllocationMembersDetailsModal;
