import React from 'react';
import { ASSESSMENT_STATUS, SALE_ORDER_STATUS } from '../../../shared/constants/Constants';
import { OrderStatus, OverlayCell } from '../../../shared/components/GenericComponent';
import { ReactComponent as IconFirstOrder } from '../../../assets/img/first-order.svg';
import { CustomRatingComponent } from '../../../shared/components';

export const OrderStatusCell = ({ order = {}, hideText, style, fromScreen = '' }) => {
  const isAssessmentDone =
    order.items?.findIndex((item) => item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED) <
    0;

  const isAssessmentInProgress =
    order.items?.findIndex(
      (item) =>
        item.qualityAssessmentStatus !== ASSESSMENT_STATUS.PENDING &&
        item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED
    ) === 0;
  return (
    <OrderStatus
      className="mt-1"
      isAssessmentDone={isAssessmentDone}
      isAssessmentInProgress={isAssessmentInProgress}
      statusLogs={order.logs}
      status={
        fromScreen === 'ORDER_ALLOCATION'
          ? SALE_ORDER_STATUS[order.orderStatus]
          : SALE_ORDER_STATUS[order.status]
      }
      hideText
      style={style}
      onClick
    />
  );
};
function aggregatorOverLay(rating) {
  return (
    <div className="d-flex flex-column">
      <p className="mb-2 font-bold">1st Order</p>
      <CustomRatingComponent
        setFieldValue={0}
        name="rating"
        error=""
        touched=""
        label="Ratings"
        value={rating}
      />
    </div>
  );
}
export function AggregatorCell({ hideNavigation = false, orderData }) {
  const overlay = aggregatorOverLay(orderData.rating);
  const isFirstOrder = orderData?.firstOrder;
  return (
    <div>
      <OverlayCell hideOverlay={!isFirstOrder} overlay={overlay}>
        <div>
          {!hideNavigation && (
            <IconFirstOrder style={{ width: '19px', opacity: isFirstOrder ? 1 : 0 }} />
          )}
          <span>{orderData?.sellerName || 'NA'}</span>
        </div>
      </OverlayCell>
    </div>
  );
}
