/* eslint-disable no-unreachable */
import { flatMap } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import LogEdit from '../../assets/img/LogEdit.svg';
import './OrderAllocationLogs.scss';
import { toLocalDate, toLocalDateTime } from '../../shared/utils/Helper';
import { useStakeholders } from '../../hooks';

const OrderAllocationLogs = ({
  orderLogs,
  setOrderLogs,
  editItemObj,
  selectOption,
  setSelectedOption,
}) => {
  const { kamUsers, currentAccount } = useStakeholders();

  const closeModal = () => {
    setOrderLogs([]);
    setSelectedOption('');
  };

  const accountNameHandler = (userId) =>
    flatMap(kamUsers)?.find((user) => user?.userId === userId)?.firstName;

  const timeDifferenceHandler = (data) => {
    const totalMinutes = data?.reduce((total, item) => total + item.timeDifferenceInMinutes, 0);

    const hours = Math.floor(totalMinutes / 60);
    const days = Math.floor(hours / 24);
    const remainingMinutes = totalMinutes % 60;

    let totalTime = '';

    if (days > 0) {
      totalTime = `${days} day${days > 1 ? 's' : ''}, ${hours % 24} hour${
        hours % 24 > 1 ? 's' : ''
      }, and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      totalTime = `${hours} hour${hours > 1 ? 's' : ''} and ${remainingMinutes} minute${
        remainingMinutes > 1 ? 's' : ''
      }`;
    } else {
      totalTime = `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }

    return totalTime;
  };

  const processData = (data) => {
    return data.map((current, index) => {
      const previous = index > 0 ? data[index - 1] : null;
      const statusHistory = [];

      if (current.status === 'REASSIGNED') {
        if (previous && previous.status === 'REASSIGNED') {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
          statusHistory.push({
            status: 'REASSIGNED',
            date: current.modifiedAt,
            comment: current.comment,
            user: current.modifiedBy,
          });
        } else if (previous && previous.status === 'HOLD') {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
          statusHistory.push({
            status: 'REASSIGNED',
            date: current.modifiedAt,
            comment: current.comment,
            user: current.modifiedBy,
          });
        } else {
          statusHistory.push({ status: 'QUEUE', date: current.createdAt });
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
          statusHistory.push({
            status: 'REASSIGNED',
            date: current.modifiedAt,
            comment: current.comment,
            user: current.modifiedBy,
          });
        }
      }

      if (current.status === 'HOLD') {
        if (previous && previous.status === 'REASSIGNED') {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        } else if (previous && previous.status === 'HOLD') {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        } else {
          statusHistory.push({ status: 'QUEUE', date: current.createdAt });
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        }
        statusHistory.push({
          status: 'HOLD',
          date: current.modifiedAt,
          comment: current.comment,
          user: current.modifiedBy,
        });
      }

      if (current.status === 'COMPLETED') {
        if (previous && (previous.status === 'REASSIGNED' || previous.status === 'HOLD')) {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        } else {
          statusHistory.push({ status: 'QUEUE', date: current.createdAt });
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        }
        statusHistory.push({
          status: 'COMPLETED',
          date: current.completedOn,
          user: current.userId,
        });
      }

      if (current.status === 'ASSIGNED') {
        if (previous && (previous.status === 'REASSIGNED' || previous.status === 'HOLD')) {
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        } else {
          statusHistory.push({ status: 'QUEUE', date: current.createdAt });
          statusHistory.push({
            status: 'ASSIGNED',
            date: current.assignedOn,
            user: current.userId,
          });
        }
      }
      // Calculate time difference between the first and last date in statusHistory
      const firstDate = new Date(
        statusHistory?.[0].status === 'QUEUE' ? statusHistory?.[1].date : statusHistory?.[0].date
      );
      const lastDate = new Date(statusHistory?.[statusHistory.length - 1].date);
      const timeDifferenceInMs = lastDate - firstDate;
      let timeDifference = '';

      const minutes = Math.floor(timeDifferenceInMs / 1000 / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      const remainingMinutes = minutes % 60;
      if (days > 0) {
        timeDifference = `${days} day${days > 1 ? 's' : ''}, ${hours % 24} hour${
          hours % 24 > 1 ? 's' : ''
        }, and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
      } else if (hours > 0) {
        timeDifference = `${hours} hour${hours > 1 ? 's' : ''} and ${remainingMinutes} minute${
          remainingMinutes > 1 ? 's' : ''
        }`;
      } else {
        timeDifference = `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
      }

      return {
        id: current.id,
        orderId: current.orderId,
        statusHistory,
        timeDifference,
        timeDifferenceInMinutes: minutes,
      };
    });
  };

  const mapData = useMemo(() => {
    if (orderLogs?.length > 0) {
      const res = processData(orderLogs);
      return res;
    }
    return [];
  }, [orderLogs]);

  return (
    <Modal
      animation
      onHide={closeModal}
      show={orderLogs}
      centered
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
      contentClassName="my-modal overflow-auto">
      <Modal.Header className="my-modal-header font-bold" closeButton>
        Allocation Logs - {editItemObj?.saleOrderId}
      </Modal.Header>
      <Modal.Body>
        {mapData?.map((logsData, index) => {
          return (
            <>
              {logsData?.statusHistory?.map((logs, ind) => {
                return (
                  <>
                    <div className="OrderAllocationLogs">
                      <div className="pl-2 pr-2">
                        <div className="log-icon-container">
                          <Image className="log-icon" src={LogEdit} />
                          {ind < logsData?.statusHistory?.length - 1 && (
                            <div className="vertical-line" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold mt-2">{logs?.status}</div>
                        <div>
                          <ul>
                            <li>
                              {logs?.status !== 'QUEUE' && (
                                <span>{accountNameHandler(logs?.user)} - </span>
                              )}
                              <span>{toLocalDateTime(logs?.date, 'DD-MMM-YYYY h:mm a')}</span>
                              {(logs?.status === 'HOLD' || logs?.status === 'REASSIGNED') && (
                                <>
                                  {' , '}
                                  <span>Comment - {logs?.comment}</span>
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {logsData?.statusHistory?.[logsData?.statusHistory?.length - 1]?.status !==
                'ASSIGNED' && (
                <div className="d-flex justify-content-end font-semibold">
                  <span className="m-2">Completion Time - {logsData?.timeDifference}</span>
                </div>
              )}
              {mapData?.length - 1 !== index && <hr />}
            </>
          );
        })}
      </Modal.Body>
      {selectOption === 'COMPLETED' && (
        <Modal.Footer className="modal-footer">
          <div className="font-semibold" style={{ display: 'flex', alignItems: 'left' }}>
            Completion Time - {timeDifferenceHandler(mapData)}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default OrderAllocationLogs;
