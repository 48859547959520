import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { QUOTES_STATUS, REQUISITION_STATUS } from '../../constants/Constants';

const KamQuotesStatusDropdown = ({ onChange, value, ...props }) => {
  const options = [
    QUOTES_STATUS.IN_REVIEW,
    QUOTES_STATUS.APPROVED,
    QUOTES_STATUS.REJECTED,
    QUOTES_STATUS.WAITING_FOR_BUYER_APPROVAL,
  ];

  return (
    <DropdownSelect
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      options={options}
      value={value}
      {...props}
    />
  );
};

KamQuotesStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default KamQuotesStatusDropdown;
