/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Modal } from 'react-bootstrap';
import { flatMap } from 'lodash';
import DropdownSelect from '../../shared/components/DropdownSelect';
import { PrimaryButton, SecondaryButton } from '../../shared/components/GenericComponent';
import { useStakeholders } from '../../hooks';
import { useOrderAllocation } from '../../hooks/hooks';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';
import { ErrorCode } from '../../shared/constants';
import { TOAST_TYPES } from '../../shared/constants/Constants';
import { FormField } from '../../shared/components';

const OrderManualAllocationModal = ({
  show,
  setShow,
  editItemObj,
  selectKey,
  getSaleOrderAllocationList,
  selectOption,
  setSelectedOption,
}) => {
  const [
    { assignSaleOrderError, assignSaleOrderLoading, assignSaleOrderValue, holdSaleOrderValue },
    { doAssignSaleOrder, doHoldSaleOrder },
  ] = useOrderAllocation();

  const { kamList, kamUsers, currentAccount } = useStakeholders();

  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [assigneeData, setAssigneeData] = useState([]);
  const [commentValue, setCommentValue] = useState('');

  useEffect(() => {
    const userRoles = ['ROLE_MP_INVOICE_PROCESSOR'];
    const users = kamList?.filter((user) =>
      user?.authorities?.some((auth) => userRoles.includes(auth))
    );
    const userNames = users?.map((item) => {
      const foundUser = flatMap(kamUsers)?.find((user) => user?.userId === item?.userId);
      return foundUser;
    });
    setAssigneeData(userNames);
  }, []);

  const closeModal = () => {
    setShow(false);
    setSelectedAssignee(null);
    setCommentValue('');
  };

  const onSavePress = async () => {
    const params =
      selectOption === 'HOLD'
        ? {
            saleOrderId: editItemObj?.saleOrderId,
            comment: commentValue,
          }
        : {
            userId: selectedAssignee?.userId,
            saleOrderId: editItemObj?.saleOrderId,
            status: selectKey,
            comment: commentValue,
          };
    if (
      (selectKey === 'QUEUE' || selectKey === 'ASSIGNED' || selectKey === 'HOLD') &&
      selectOption !== 'HOLD'
    ) {
      const res = await doAssignSaleOrder(params);
      const error = hasError(res);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
        return;
      }
      if (res) {
        if (selectKey === 'QUEUE') {
          getSaleOrderAllocationList({ allocationStatus: 'QUEUE', page: 0, size: 20 });
          displayToastMessage(TOAST_TYPES?.SUCCESS, 'Manual assignment successfull');
        }
        if (selectKey === 'ASSIGNED') {
          getSaleOrderAllocationList({ allocationStatus: 'ASSIGNED', page: 0, size: 20 });
          displayToastMessage(TOAST_TYPES?.SUCCESS, 'Reassign assignment successfull');
        }
        if (selectKey === 'HOLD') {
          getSaleOrderAllocationList({ allocationStatus: 'HOLD', page: 0, size: 20 });
          displayToastMessage(TOAST_TYPES?.SUCCESS, 'Reassign assignment successfull');
        }
      }
    } else if (selectOption === 'HOLD') {
      const response = await doHoldSaleOrder(params);
      const error = hasError(response);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
        return;
      }
      getSaleOrderAllocationList({ allocationStatus: 'ASSIGNED', page: 0, size: 20 });
      displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.ORDER_ON_HOLD);
    }

    setShow(false);
    setSelectedOption('');
    setSelectedAssignee(null);
  };

  return (
    <Modal
      animation
      onHide={closeModal}
      show={show}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
      contentClassName="my-modal overflow-auto">
      <Modal.Header className="my-modal-header font-bold" closeButton>
        {selectKey === 'QUEUE' ? 'Manual Assign' : selectOption === 'HOLD' ? 'Hold' : 'Reassign'} -{' '}
        {editItemObj?.saleOrderId}
      </Modal.Header>
      <Modal.Body className="pb-5">
        {selectOption !== 'HOLD' && (
          <>
            <div className="text-small text-muted">
              Select Assignee<span className="mandatory-astrik">*</span>
            </div>
            <DropdownSelect
              onChange={(selectedOption) => {
                setSelectedAssignee(selectedOption);
              }}
              value={selectedAssignee}
              options={assigneeData}
              getOptionValue={(option) => option?.userId}
              getOptionLabel={(option) => option?.firstName}
              isDisabled={false}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </>
        )}

        {selectKey !== 'QUEUE' && (
          <Form>
            <Form.Row className="mt-3">
              <Form.Group as={Col} md="12">
                <FormField
                  type="textarea"
                  label="Comment"
                  style={{ height: '50px' }}
                  labelClass="required"
                  placeholder="Enter comments"
                  onChange={(e) => setCommentValue(e?.target?.value)}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton buttonStyle={{ width: '174px' }} label="Cancel" onClick={closeModal} />
        <PrimaryButton
          onClick={onSavePress}
          label="Save"
          buttonStyle={{ width: '174px' }}
          disabled={
            selectKey !== 'QUEUE' ? !selectedAssignee && commentValue === '' : !selectedAssignee
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default OrderManualAllocationModal;
