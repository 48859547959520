/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React from 'react';
import RecyclerReceivablesPageNew from './views/RecyclerReceivables/RecyclerReceivablesPageNew';
import IndividualReceivables from './views/RecyclerReceivables/IndividualReceivables';
import QuotesList from './views/Quotes/QuotesList';
import SegmentationPage from './views/Segmentation/SegmentationPage';
import OrderAllocation from './views/OrderAllocation/OrderAllocation';
import FinanceWorkarea from './views/FinanceWorkarea/FinanceWorkarea';

const SoPaymentDetailsPage = React.lazy(() =>
  import('./views/AccountsPaymentsPage/SoPaymentDetailsPage')
);

const NewRolePage = React.lazy(() => import('./views/UsersPage/NewRolePage'));

const DefaultScoreSettings = React.lazy(() =>
  import('./features/Aggregators/DefaultScoreSettings/DefaultScoreSettings')
);

const LeadsDetails = React.lazy(() => import('./features/Leads/LeadsDetails'));

const GstRating = React.lazy(() => import('./features/Aggregators/GstRating'));
const AggregatorRating = React.lazy(() => import('./features/Aggregators/AggregatorRating'));

const OsvRating = React.lazy(() => import('./features/Aggregators/OsvRating'));

const RecyclerPage = React.lazy(() => import('./views/RecyclersPage/RecyclersPage'));
const GlobalBuyerSellerMapListPage = React.lazy(() =>
  import('./views/BuyerSellerMappingPage/GlobalBuyerSellerMapListPage')
);
const RecyclerAddPage = React.lazy(() => import('./views/RecyclersPage/RecyclerAdd/RecyclerAdd'));
const LogisticsPage = React.lazy(() => import('./views/LogisticsPage/LogisticsPage'));
const TransportersPage = React.lazy(() =>
  import('./views/TransportersPage/TransporterMainPage/TransportersPage')
);
const GstinPaymentsPage = React.lazy(() => import('./views/GstinTDSPayments/GstinPaymentsPage'));
const TransportersDetailsPage = React.lazy(() =>
  import('./views/TransportersPage/TransporterAdd/TransporterDetailsPage')
);
const PurchaseOrderListPage = React.lazy(() =>
  import('./views/OrderManagementPage/PurchaseOrderPage/POListPage/POListPage')
);
const CreatePOPage = React.lazy(() =>
  import('./views/OrderManagementPage/PurchaseOrderPage/CreatePurchaseOrder/CreatePOPage')
);
const AccountsPaymentsPage = React.lazy(() => import('./views/AccountsPaymentsPage/PaymentsPage'));

const PaymentsDetailsPage = React.lazy(() =>
  import('./views/AccountsPaymentsPage/PaymentsDetailsPage')
);
const PODetailsPage = React.lazy(() =>
  import('./views/OrderManagementPage/PurchaseOrderPage/PruchaseOrderDetails/PurchaseOrderDetails')
);
const AggregatorPage = React.lazy(() => import('./views/AggregatorPage/AggregatorPage'));
const CampaignsDetailsPage = React.lazy(() => import('./views/CampaignsList/CampaignsDetailsPage'));
const AggregatorDetails = React.lazy(() => import('./views/AggregatorPage/AggregatorDetails'));

const SaleOrderPage = React.lazy(() => import('./views/SaleOrderPage/SaleOrderPage'));
const SaleOrderDetailsPage = React.lazy(() =>
  import('./views/SaleOrderPage/SaleOrderDetailsPage.js')
);
const CampaignDetail = React.lazy(() => import('./views/CampaignsList/CampaignDetail'));
const KamManagement = React.lazy(() => import('./views/KAMUserPage/KamManagement'));
const RequisitionListPage = React.lazy(() =>
  import('./views/RequisitionsPage/RequisitionListPage')
);

const KamRequisitionDetailsPage = React.lazy(() =>
  import('./features/Requisitions/KamRequisitionDetailsPage')
);

const Reassign = React.lazy(() => import('./features/SaleOrder/Reassignment/ReassignmentModal'));

const ActiveCities = React.lazy(() => import('./views/City/ManageCities'));
const QualityRiskFactor = React.lazy(() => import('./views/QualityRiskFactor/QRF'));
const GlobalAggPaymentTerm = React.lazy(() =>
  import('./views/AggregatorPaymentTerm/GlobalAggPaymentTerm')
);
const ItemInformation = React.lazy(() => import('./views/ItemInformation/ItemInformation'));
const Campaigns = React.lazy(() => import('./views/Campaigns/Campaigns'));
const CampaignsPage = React.lazy(() => import('./views/CampaignsList/CampaignsPage'));

const RecyclerPaymentTerm = React.lazy(() => import('./views/RecyclersPage/RecyclerPaymentTerm'));

const CreditTimeLimits = React.lazy(() => import('./views/CreditTimeLimits/CreditTimeLimits'));

const PricingList = React.lazy(() => import('./views/PricingListNewPage/PricingListPage'));
const UsersPage = React.lazy(() => import('./views/UsersPage/UsersPage'));
const LogisticsManagerPage = React.lazy(() =>
  import('./views/LogisticsManagerPage/LogisticsManagerPage')
);

const KamListingsPage = React.lazy(() => import('./views/ListsPage/KamListsPage'));
const KamListingsDetailsPage = React.lazy(() => import('./views/ListsPage/KamListingDetailsPage'));

const Invoices = React.lazy(() => import('./views/Invoices/Invoices'));
const Logs = React.lazy(() => import('./views/Logs/logs'));

const LogisticItemMaster = React.lazy(() =>
  import('./views/LogisticItemsMaster/LogisticItemsMaster')
);

const GSTRFilingStatus = React.lazy(() => import('./views/GSTRFilingStatus/GSTRFilingStatusPage'));

const GstTab = React.lazy(() => import('./views/GSTTab/GstTab.js'));

const FulfillmentDashboard = React.lazy(() => import('./views/dashboards/FulfillmentDashboard.js'));

const ProductDashboard = React.lazy(() => import('./views/dashboards/ProductDashboard.js'));

const ControlRoomDashboard = React.lazy(() => import('./views/dashboards/ControlRoomDashboard.js'));

const FindUsersPage = React.lazy(() => import('./views/ListsPage/FindUsersPage'));

const DynamicTrackingMainPage = React.lazy(() =>
  import('./features/Logistics/TrackingDashBoard/DynamicTracking/DynamicTrackingMainPage')
);

const Receivables = React.lazy(() => import('./views/Receivables/Receivables'));
const ReceivablesDetails = React.lazy(() => import('./views/Receivables/ReceivablesDetails'));
const AggregatorPayments = React.lazy(() =>
  import('./views/AggregatorPayments/AggregatorPayments')
);

const TransporterPayments = React.lazy(() =>
  import('./views/TransporterPaymentsPage/TransporterPaymentsPage.js')
);

const TrackingConfig = React.lazy(() => import('./views/TrackingConfig/PreDispatchTrackingPage'));
const ListingAssignOpenPO = React.lazy(() => import('./features/KamListings/ListingAssignOpenPO'));
const DailyPrices = React.lazy(() => import('./views/DailyPrices/DailyPrices'));
const AggregatorAppLeadsDetails = React.lazy(() =>
  import('./views/LeadsPage/AggregatorAppLeadsDetails')
);
const RecyclerAppLeadsDetails = React.lazy(() =>
  import('./views/RecyclerLeads/RecyclerAppLeadsDetails')
);

const AssetManagementPage = React.lazy(() => import('./views/ItemInformation/AssetManagementPage'));
const ListingDetailsItad = React.lazy(() => import('./views/ListsPage/ListingDetailsItad'));

const AggregatorOnBoarding = React.lazy(() =>
  import('./features/Aggregators/Aggregators_AppLeads/AggregatorOnBoarding.js')
);

const ManageUpdatesPage = React.lazy(() => import('./views/ManageUpdates/ManageUpdatesPage.js'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  {
    path: '/customers/recyclers',
    name: 'Manage Recyclers',
    component: RecyclerPage,
  },
  {
    path: '/recyclers/add',
    name: 'Add Recycler',
    component: RecyclerAddPage,
  },
  {
    path: '/recyclers/:id/edit',
    name: 'Update Recycler',
    component: RecyclerAddPage,
  },
  {
    path: '/logistics/logisticroutes',
    name: 'Logistics Management',
    component: LogisticsPage,
  },
  {
    path: '/customers/transporters',
    name: 'Transporters',
    component: TransportersPage,
  },
  {
    path: '/logistics/logisticsmanager',
    name: 'Logistics Manager',
    component: LogisticsManagerPage,
  },
  {
    path: '/items/logisticsitems',
    name: 'Logistic Item Master',
    component: LogisticItemMaster,
  },
  {
    path: '/transport/create',
    name: 'Add Transporter',
    component: TransportersDetailsPage,
  },
  {
    path: `/transport/:id/edit`,
    name: 'Update Transporter',
    component: TransportersDetailsPage,
  },
  {
    path: '/orders/purchaseorder',
    name: 'Manage Purchase Order',
    component: PurchaseOrderListPage,
  },
  {
    path: '/orders/create-po',
    name: 'Create Purchase Order',
    component: CreatePOPage,
  },
  {
    path: `/orders/:id/edit`,
    name: 'Update Purchase Order',
    component: CreatePOPage,
  },
  {
    path: '/orders/:id/details-po',
    name: 'Details Purchase Order',
    component: PODetailsPage,
  },
  {
    path: '/customers/aggregators',
    name: 'Manage Aggregators',
    component: AggregatorPage,
  },

  {
    path: '/:id/aggregatorDetails',
    name: 'Aggregator Details',
    component: AggregatorDetails,
  },

  {
    path: '/orders/saleordernew/:id',
    name: 'Sale Order Details',
    component: SaleOrderDetailsPage,
  },

  {
    path: '/orders/saleorder',
    name: 'Manage Sale Orders',
    component: SaleOrderPage,
  },
  {
    path: '/listings',
    name: 'Kam Listings',
    component: KamListingsPage,
  },
  {
    path: '/lists/kam-quotes',
    name: 'Kam Requisition Quotes',
    component: QuotesList,
  },
  {
    path: '/lists/user-campaigns',
    name: 'Find Users Page',
    component: FindUsersPage,
  },
  {
    path: '/users/role/:edit/:name',
    name: 'Edit New Role',
    component: NewRolePage,
  },
  {
    path: '/users/new-role',
    name: 'New Role',
    component: NewRolePage,
  },
  {
    path: '/users/:clone/:name',
    name: 'Clone Role',
    component: NewRolePage,
  },
  {
    path: '/lists/listing-details/:id',
    name: 'Kam Listings Details',
    component: KamListingsDetailsPage,
  },
  {
    path: '/accounts/payments',
    name: 'Make Payments',
    component: AccountsPaymentsPage,
  },
  {
    path: '/accounts/recyclerReceivables',
    name: 'Recycler Receivables',
    component: RecyclerReceivablesPageNew,
  },
  {
    path: '/recyclerReceivables/:id',
    name: 'Recyler Receivables Recycler',
    component: IndividualReceivables,
  },
  {
    path: '/accounts/invoices',
    name: 'Invoices',
    component: Invoices,
  },
  {
    path: '/invoices/paid/:id',
    name: 'Invoices',
    component: Invoices,
  },
  {
    path: '/invoices/unpaid/:id',
    name: 'Invoices',
    component: Invoices,
  },
  {
    path: '/payments/gstpayments',
    name: 'GST Payment',
    component: GstinPaymentsPage,
  },
  {
    path: '/gst/gstfilings',
    name: 'GST Filing Status',
    component: GSTRFilingStatus,
  },
  {
    path: '/gst/gstreconciliation',
    name: 'GST Reconciliation',
    component: GstTab,
  },
  {
    path: '/accounts/payment-details/:id',
    name: 'Payments Details',
    component: PaymentsDetailsPage,
  },
  {
    path: '/accounts/so-payment-details/:id',
    name: 'Payments Details',
    component: SoPaymentDetailsPage,
  },
  {
    path: '/systemconfig/kam',
    name: 'Kam Management',
    component: KamManagement,
  },
  {
    path: '/dailyprices',
    name: 'Daily Prices',
    component: DailyPrices,
  },
  {
    path: '/requisitions',
    name: 'Requisition Management',
    component: RequisitionListPage,
  },
  {
    path: '/requisition-details/:id',
    name: 'Kam Listings Details',
    exact: true,
    component: KamRequisitionDetailsPage,
  },
  {
    path: '/reassign',
    name: 'Sale Order Reassignment',
    component: Reassign,
  },
  {
    path: '/systemconfig/activecities',
    name: 'Active Cities',
    component: ActiveCities,
  },
  {
    path: '/systemconfig/rm&qrf',
    name: 'RM & QRF',
    component: QualityRiskFactor,
  },
  {
    path: '/systemconfig/aggregatorpaymentterms',
    name: 'Aggregator Payment Terms',
    component: GlobalAggPaymentTerm,
  },
  {
    path: '/systemconfig/recyclerpaymentterms',
    name: 'Recycler Payment Terms',
    component: RecyclerPaymentTerm,
  },
  {
    path: '/systemconfig/manageupdates',
    name: 'Manage Updates',
    component: ManageUpdatesPage,
  },
  {
    path: '/systemconfig/credit&timelimits',
    name: 'Credit & Time Limits',
    component: CreditTimeLimits,
  },
  {
    path: '/items',
    name: 'Item Information',
    component: ItemInformation,
  },
  {
    path: '/systemconfig/logs',
    name: 'logs',
    component: Logs,
  },
  {
    path: '/source-tracking',
    name: 'Campaigns',
    component: Campaigns,
  },
  {
    path: '/campaignlist',
    name: 'CampaignsPage',
    component: CampaignsPage,
  },
  {
    path: '/campaign/create',
    name: 'CampaignsDetailsPage',
    component: CampaignsDetailsPage,
  },
  {
    path: '/campaign-by-id/:id',
    name: 'CampaignDetail',
    component: CampaignDetail,
  },
  {
    path: '/pricinglist',
    name: 'Pricing List',
    component: PricingList,
  },
  {
    path: '/systemconfig/users',
    name: 'Users',
    component: UsersPage,
  },
  {
    path: '/systemconfig/usermapping',
    name: 'Buyer Seller Mapping',
    component: GlobalBuyerSellerMapListPage,
  },
  {
    path: '/dashboards/fulfillment',
    name: 'Fulfillment',
    component: FulfillmentDashboard,
  },
  {
    path: '/dashboards/product',
    name: 'Product',
    component: ProductDashboard,
  },
  {
    path: '/dashboards/controlroom',
    name: 'ControlRoom',
    component: ControlRoomDashboard,
  },
  {
    path: '/logistics/trackingdashboard',
    name: 'TrackingDashboard',
    component: DynamicTrackingMainPage,
  },
  {
    path: '/receivables',
    name: 'Receivables',
    component: Receivables,
  },
  {
    path: '/recycler/receivables/:id',
    name: 'Receivable Details',
    component: ReceivablesDetails,
  },
  {
    path: '/payments/aggregatorpayments',
    name: 'Aggregator Payments',
    component: AggregatorPayments,
  },
  {
    path: '/payments/transporterpayments',
    name: 'Transporter Payments',
    component: TransporterPayments,
  },
  {
    path: '/systemconfig/pre-dispatchtrackingcheck',
    name: 'Dispatch Tracking',
    component: TrackingConfig,
  },
  {
    path: '/payments/aggregatorpayments',
    name: 'Aggregator Payments',
    component: AggregatorPayments,
  },
  {
    path: '/payments/transporterpayments',
    name: 'Transporter Payments',
    component: TransporterPayments,
  },
  {
    path: '/:id/aggregatorDetail/Gst',
    name: 'Gst Rating',
    component: GstRating,
  },
  {
    path: '/:id/aggregatorDetail/Osv',
    name: 'Osv Rating',
    component: OsvRating,
  },
  {
    path: '/:id/aggregatorDetails',
    name: 'Seller Rating',
    component: AggregatorRating,
  },
  {
    path: '/:id/aggregatorDetail/DefaultScoreSettings',
    name: 'Default Scores',
    component: DefaultScoreSettings,
  },
  {
    path: '/:id/leadsDetails',
    name: 'Default Scores',
    component: LeadsDetails,
  },
  {
    path: '/:id/assign-buyer',
    name: 'Assign Buyer',
    component: ListingAssignOpenPO,
  },
  {
    path: '/:id/aggregator/appLeadDetails',
    name: 'App Lead Details',
    component: AggregatorOnBoarding,
  },
  {
    path: '/:id/recycler/appLeadDetails',
    name: 'App Lead Details',
    component: RecyclerAppLeadsDetails,
  },
  {
    path: '/:id/add/aggregator/appLeadDetails',
    name: 'App Lead Details',
    component: AggregatorOnBoarding,
  },
  {
    path: '/:id/add/recycler/appLeadDetails',
    name: 'App Lead Details',
    component: RecyclerAppLeadsDetails,
  },
  {
    path: '/attributeDetails/:id',
    name: 'Asset Management',
    component: AssetManagementPage,
  },
  {
    path: '/listing-details-itad',
    name: 'Listing Details',
    component: ListingDetailsItad,
  },
  {
    path: '/systemconfig/segmentation',
    name: 'Segmentation',
    component: SegmentationPage,
  },
  {
    path: '/orderallocation',
    name: 'Order Allocation',
    component: OrderAllocation,
  },
  {
    path: '/financeworkarea',
    name: 'Finance Workarea',
    component: FinanceWorkarea,
  },
];

export default routes;
