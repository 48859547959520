import React, { useCallback, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import './RcylUpload.scss';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { uploadDocument } from '../../utils/FileUpload';
import { ReactComponent as RecykalIcon } from '../../../assets/img/recykal-icon.svg';
import { ReactComponent as UploadImage } from '../../../assets/img/upload-image.svg';
import Loader from '../Loader/Loader';

const RcylUpload = (props) => {
  const {
    id,
    onFileSelected,
    onUploadSuccess,
    uploadedFiles,
    uploadComponent,
    displayComponent,
    style,
    hideUpload,
    activeIndex,
    isUploadedByKam,
    onView,
    ...restProps
  } = {
    ...props,
  };

  const [uploadingFile, setUploadingFile] = useState();
  /**
   * callback called when file choosen from filemanager
   * @type {(...args: any[]) => any}
   */
  const onDrop = useCallback(([file]) => {
    file.status = 'SELECTED';
    onFileSelected && onFileSelected(file, props.document?.id, document?.type);
    const fileDetails = {
      entity: 'SALE_ORDER',
      entityId: id,
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: 'SO_DOC',
    };
    function signedUrlCallback(data) {
      file.status = 'PROGRESS';
      setUploadingFile(file);
    }
    function onUploaded(data) {
      file.status = 'COMPLETED';
      setUploadingFile(null);
      onUploadSuccess && onUploadSuccess(data);
    }
    function onUploadFailed(error) {
      file.status = 'FAILED';
      setUploadingFile(null);
    }
    function onUploading(data) {
      // setUploadingFile(data);
    }

    uploadDocument(fileDetails, file, signedUrlCallback, onUploaded, onUploadFailed, onUploading);
  }, []);

  /**
   * DropZone hooks
   */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    maxSize: '10485760',
    multiple: undefined,
  });

  return (
    <div className="rcylUpload" style={style} {...restProps}>
      <Row className="rcyl-upload-container">
        {uploadedFiles?.length > 0 &&
          uploadedFiles?.map((file, idx) => (
            // (displayComponent && displayComponent(file, idx)) ||
            <Col className="rcyl-display-view" key={`uploaded-file-${idx}`}>
              <Image
                onClick={() => onView(file, idx)}
                src={file.viewUrl}
                className={classNames('img-thumbnail d-block w-100', {
                  active: activeIndex === idx,
                })}
              />
              {isUploadedByKam(file?.uploadedBy) && <RecykalIcon className="recykalIcon" />}
            </Col>
          ))}

        {uploadingFile?.status === 'PROGRESS' ? (
          <Col className="rcyl-upload-view" key="uploading-file">
            <div className="img-thumbnail w-100">
              <Loader size="sm" hideText />
            </div>
          </Col>
        ) : (
          !hideUpload && (
            <Col className="rcyl-upload-view ">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {uploadComponent || (
                  <div className="img-thumbnail w-100">
                    <UploadImage />
                  </div>
                )}
              </div>
            </Col>
          )
        )}
      </Row>
    </div>
  );
};
export default RcylUpload;
