import { filter, map, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useRole } from '../../../hooks';
import DropdownSelect from '../DropdownSelect';

const RolesDropdown = ({ onChange, value, belongsToAddUser, ...props }) => {
  const { rolesData } = useRole();

  const rolesListOptions = map(rolesData, (option) => {
    return {
      id: option?.name,
      name: option?.title,
      status: option?.archive,
    };
  });

  const filteredRolesListOptions =
    belongsToAddUser === true
      ? filter(rolesListOptions, (option) => !isUndefined(option?.name) && option?.status !== true)
      : filter(rolesListOptions, (option) => !isUndefined(option?.name));

  return (
    <DropdownSelect
      onChange={onChange}
      options={filteredRolesListOptions || []}
      value={value}
      {...props}
      getOptionLabel={(option) => {
        return option?.name;
      }}
      getOptionValue={(option) => option?.id}
    />
  );
};

RolesDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RolesDropdown;
